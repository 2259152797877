/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

const _style = {
  heroContainer: {
    bg: `white`,
    pt: [2, 6],
    pb: [2, 3],
    px: [2, 0]
  }
};

export default _style;
